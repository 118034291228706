<template>
  <div
    class="kanban-card"
    :style="{ 'border-left': `5px solid ${borderColor}` }"
  >
    <v-container fluid class="m-0" style="padding: 0 10% 0 10%">
      <v-row class="kanban-row">
        <v-col cols="11" style="padding: 0">
          <strong>Marca: {{ block.brand }}</strong>
        </v-col>
        <v-col cols="1" style="padding: 0">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="black" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list v-if="permissions && (permissions.equipment.view || permissions.techReviews.additionalAction1 || permissions.techReviews.view)">
              <v-list-item v-if="permissions.techReviews.view" @click="viewTechReview">
                <v-list-item-title>Ver Revisión</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="permissions.techReviews.additionalAction1" @click="makeTechReview">
                <v-list-item-title>Realizar Revisión</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="permissions.equipment.view" @click="getMachineById">
                <v-list-item-title>Ver detalle</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="kanban-row">
        <p>Modelo: {{ block.title }}</p>
      </v-row>
      <v-row class="kanban-row">
        <p>Número de Serie: {{ block.serialNumber }}</p>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MachineDetailCard from "@/frimar/components/MachineDetailCard.vue";
import axios from "axios";

export default {
  data() {
    return {
      showDetailModal: false,
      currentItem: {
        id: null,
        created: "",
        modified: "",
        deleted: null,
        brand: "",
        type: "",
        model: "",
        innerId: "",
        serialNumber: "",
        youtubeUrl: "",
        images: null,
      },
    };
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      required: false,
    },
  },
  methods: {
    moveToNextStage() {
      this.$emit("move-to-next-stage", this.block);
    },
    viewTechReview() {
      this.$router.push("/app/techReviewDetail/" + this.block.id);
    },
    makeTechReview() {
      this.$router.push("/app/techReviewForm/" + this.block.id);
    },
    closeDetailModal() {
      this.showDetailModal = false;
    },
    getMachineById() {
      this.$router.push("/app/machine/" + this.block.machineId);
    },
    viewItem(item) {
      this.showDetailModal = true;
      this.currentItem = {
        ...item,
        youtubeUrls: item.youtubeUrl ? item.youtubeUrl.split(",") : [],
      };
    },
  },
  computed: {
    borderColor() {
      switch (this.block.overDate) {
        case "low":
          return "green";
        case "medium":
          return "yellow";
        case "high":
          return "red";
        default:
          return "transparent";
      }
    },
  },
};
</script>
<style scoped>
.kanban-card {
  background-color: #fff;
  margin: 0.5rem 0;
  padding: 3px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.kanban-row {
  margin: 0;
  padding: 0;
}

.kanban-row strong,
.kanban-row p {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 2px 0 !important;
}
</style>
