const state = () => ({
  isTokenValid: false,
  accessToken: localStorage.getItem("access_token") || null, // makes sure the user is logged in even after
  refreshToken: localStorage.getItem("refresh_token") || null, // makes sure the user is logged in even after
});

// getters
const getters = {
  isTokenValid(state) {
    return state.isTokenValid;
  },
  token(state) {
    return state.accessToken;
  },
  refresh(state) {
    return state.refreshToken;
  },
};

// actions
const actions = {
  setAccessToken({ commit }, token) {
    commit("setAccessToken", token);
  },
  logout({ commit }) {
    commit("logout");
  },
  setRefreshToken({ commit }, token) {
    commit("setRefreshToken", token);
  },
};

// mutations
const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token;
  },
  logout(state) {
    state.accessToken = null;
  },
  setRefreshToken(state, token) {
    state.refreshToken = token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
