<template>
  <v-card class="pt-5 pb-5">
    <v-container fluid>
      <v-row class="pr-5">
        <v-card-title primary-title class="text-h5"
          >Detalles del Equipo</v-card-title
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="showEditButton"
          @click="openEditModalFromDetails"
          class="btnApplyFilter"
        >Editar</v-btn>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-card-text
            ><span class="font-weight-bold">Marca: </span
            >{{ currentItem.brand }}</v-card-text
          >
          <v-card-text
            ><span class="font-weight-bold">Tipo: </span
            >{{ currentItem.typeName }}</v-card-text
          >
          <v-card-text
            ><span class="font-weight-bold">Modelo: </span
            >{{ currentItem.model }}</v-card-text
          >
          <v-card-text
            ><span class="font-weight-bold">Número de Serie: </span
            >{{ currentItem.serialNumber }}</v-card-text
          >
          <v-card-text
            ><span class="font-weight-bold">Clave interna: </span
            >{{ currentItem.innerId }}</v-card-text
          >
        </v-col>
        <v-col cols="12" md="6">
          <v-card-title class="font-weight-bold">Enlaces</v-card-title>
          <v-card-text>
            <div v-if="currentItem.youtubeUrl">
              <a
                v-for="(url, index) in currentItem.youtubeUrl.split(',')"
                :key="index"
                :href="url"
                target="_blank"
                >{{ url }}</a
              >
              <br v-for="(url, index) in currentItem.youtubeUrl.split(',')" />
            </div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row v-if="currentItem.images && currentItem.images.length > 0">
        <v-carousel
          max-width="444px"
          height="250px"
          :hide-delimiters="currentItem.images.length === 1"
          :show-arrows="currentItem.images.length > 1"
        >
          <v-carousel-item
            v-for="(image, index) in getImagePaths(currentItem.images)"
            :key="index"
            cover
          >
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img :src="image" style="max-width: 100%; max-height: 100%" />
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-row>
        <v-spacer></v-spacer>
        <single-btn
          class="mt-3"
          :isPrimary="false"
          buttonText="Cerrar"
          :clickHandler="onCancel"
          >Cerrar</single-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import SingleBtn from "@/frimar/components/singleBtn.vue";

export default {
  name: "MachineDetailCard",
  components: {
    SingleBtn,
  },
  props: {
    currentItem: {
      type: Object,
      required: true,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    openEditModalFromDetails() {
      this.$emit("edit", { ...this.currentItem });
    },
    getImagePaths(images) {
      return images.map((image) => {
        return image.imagePath;
      });
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
