<template>
  <div>
    <v-card class="pa-4">
      <!-- Filtros y Búsqueda -->
      <v-row>
        <!-- TEXT CARD -->
        <v-col cols="12" md="4">
          <v-card-title primary-title class="text-h5">
            Listado de Revisiones Técnicas
          </v-card-title>
          <v-card-text>
            Aquí se muestran los procesos de revisiones técnicas para
            montacargas y dobladoras
          </v-card-text>
        </v-col>

        <!-- FILTER OPTIONS -->
        <v-col  cols="12" md="8">
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                v-model="selectedType"
                :items="machineTypes"
                item-value="id"
                item-text="name"
                label="Tipo"
                single-line
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Ingresa Serie o clave interna"
                single-line
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                v-model="showFinalized"
                label="Mostrar finalizados"
                single-line
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              class="btnDeleteFilter mr-3"
              outlined
              @click="onClearSearch"
              >Borrar</v-btn
            >
            <v-btn class="btnApplyFilter" @click="onSearch">Aplicar</v-btn>
          </v-row>
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-data-table
        :headers="headers"
        :items="techReviews"
        class="elevation-1"
        :server-items-length="totalItems"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
        }"
        disable-sort
        @click:row="viewItem"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="black" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list v-if="permissions && (permissions.edit || permissions.create || permissions.view)">
              <v-list-item @click="viewItem(item)">
                <v-list-item-title>Ver detalle</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.isFinalized && permissions.additionalAction1"
                @click="makeTechReview(item)"
              >
                <v-list-item-title>Realizar revisión</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.isFinalized && permissions.edit"
                @click="onMarkAsFinalized(item.id)"
              >
                <v-list-item-title>Reactivar revisión</v-list-item-title>
              </v-list-item>
              <v-list-item v-else-if="!item.isFinalized && permissions.edit" @click="onMarkAsFinalized(item.id)">
                <v-list-item-title>Finalizar revisión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.isFinalized="{ item }">
          <v-chip
            pill
            v-if="item.isFinalized"
            color="#17ad37"
            text-color="#ffffff"
          >
            <strong>Finalizado</strong>
          </v-chip>
          <v-chip pill v-else color="#fdc408" text-color="#ffffff">
            <strong>En curso</strong>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal Finalizar -->
    <v-dialog v-model="showConfirmModal" max-width="1200px" class="pa-3">
      <v-card>
        <v-card-title>Finalizar revisión</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas finalizar la revisión?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <single-btn
            :isPrimary="false"
            buttonText="Cancelar"
            :clickHandler="() => (showConfirmModal = false)"
            class="mr-3"
          ></single-btn>
          <v-btn color="#fdc408" @click="markAsFinalized"
            ><span style="color: #ffffff">Confirmar</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.background {
  background-image: url("../../assets/img/welcome.jpg");
  background-size: cover;
  background-position: center;
  height: 30em;
  position: relative;
  /* Añade posición relativa para .background */
}

.overlay {
  position: absolute;
  /* Cambia la posición a absoluta */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del contenedor .background */
  height: 100%;
  /* Ocupa toda la altura del contenedor .background */
  display: flex;
  /* Hace que los elementos se alineen en el centro */
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(122, 118, 57, 0.619);
  /* Ajusta el color y la opacidad según sea necesario */
}

.welcome-text,
.user-text {
  color: white;
  margin: 0;
  /* Elimina los márgenes predeterminados */
  display: inline-block;
  /* Cambia el comportamiento a inline-block */
}

.btnDeleteFilter {
  border: 1px solid #000;
  /* borde negro */
  border-radius: 10px;
  /* bordes redondeados */
  color: #000;
  /* texto negro */
  background-color: white;
  /* fondo transparente */
  padding: 10px 25px;
  /* relleno horizontal y vertical */
}

.btnApplyFilter {
  border-radius: 10px;
  /* bordes redondeados */
  color: #fff !important;
  /* texto blanco */
  background-color: #37474f;
  /* color de fondo oscuro */
  padding: 10px 25px;
  /* relleno horizontal y vertical */
  background-image: linear-gradient(to right, #5f5f5f, #000000);
  /* degradado de ejemplo */
}
</style>
<script>
import axios from "axios";
import SingleBtn from "@/frimar/components/singleBtn.vue";
import {ViewsEnum} from "@/enums";

export default {
  name: "Welcome",
  components: { SingleBtn },
  data() {
    return {
      selectedType: null,
      showConfirmModal: false,
      finalizeId: null,
      page: 1,
      pageSize: 10,
      search: "",
      machineTypes: [],
      headers: [
        { text: "Marca", value: "machine.brand" },
        { text: "Modelo", value: "machine.model" },
        { text: "Tipo", value: "machine.type" },
        { text: "Clave interna", value: "machine.innerId" },
        { text: "Fecha", value: "created" },
        { text: "Numero de Serie", value: "machine.serialNumber" },
        { text: "Finalizado", value: "isFinalized" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      actions: { ver: true, editar: true, eliminar: true },
      techReviews: [],
      totalItems: 0,
      showFinalized: true,
      permissions: null,
    };
  },
  computed: {
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
  },
  methods: {
    viewItem(item) {
      if (this.permissions && this.permissions.view) {
        this.$router.push("/app/techReviewDetail/" + item.id);
      }
    },
    makeTechReview(item) {
      this.$router.push("/app/techReviewForm/" + item.id);
    },
    getTechReview() {
      let fullUrl =
        process.env.VUE_APP_APIURL +
        "techReviews/?page=" +
        this.page +
        "&items=" +
        this.pageSize +
        "&showFinalized=" +
        this.showFinalized;
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.techReviews = response.data.items;
          this.totalItems = response.data.total;
        });
    },
    onSearch() {
      this.page = 1;
      let fullUrl =
        process.env.VUE_APP_APIURL +
        "techReviews/?page=" +
        this.page +
        "&items=" +
        this.pageSize +
        "&showFinalized=" +
        this.showFinalized;

      if (this.search) {
        fullUrl += "&search=" + this.search;
      }

      if (this.selectedType) {
        fullUrl += "&type=" + this.selectedType;
      }

      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.techReviews = response.data.items;
          this.totalItems = response.data.total;
        });
    },
    onClearSearch() {
      this.page = 1;
      this.search = null;
      this.selectedType = null;
      this.showFinalized = true;
      this.getTechReview();
    },
    getMachineTypes() {
      let fullUrl = process.env.VUE_APP_APIURL + "machineTypes/";
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.machineTypes = response.data;
        });
    },
    onMarkAsFinalized(id) {
      this.finalizeId = id;
      this.showConfirmModal = true;
    },
    markAsFinalized() {
      let fullUrl =
        process.env.VUE_APP_APIURL + "techReview/?id=" + this.finalizeId;
      axios
        .put(
          fullUrl,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then(() => {
          this.getTechReview();
          this.finalizeId = null;
          this.showConfirmModal = false;
        });
    },
    updatePagination(opts) {
      if (this.pageSize !== opts.itemsPerPage) {
        this.pageSize = opts.itemsPerPage;
        this.page = 1;
      }
      this.page = opts.page;
      this.getTechReview();
    },
    getPermissionsForView() {
      let currentView = this.drawerItems.find(
        (view) => view.value === ViewsEnum.TECH_REVIEW
      );
      if (currentView) {
        this.permissions = currentView;
      } else {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    this.getTechReview();
    this.getMachineTypes();
    this.getPermissionsForView();
  },
};
</script>
