var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card-title',{staticClass:"text-h5",attrs:{"primary-title":""}},[_vm._v(" Listado de Equipos ")]),_c('v-card-text',[_vm._v(" Aqui se muestran los equipos dados de alta en el sistema ")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',{staticClass:"pr-5 mt-4"},[_c('v-spacer'),(_vm.permissions && _vm.permissions.create)?_c('v-btn',{staticClass:"btnDeleteFilter mr-3",attrs:{"color":"black","outlined":""},on:{"click":_vm.createItem}},[_vm._v("Crear Equipo")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.types,"item-value":"id","item-text":"name","label":"Tipo","single-line":"","clearable":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.statusList,"label":"Estatus","single-line":"","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ingresa Serie o clave interna","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"pr-5 mb-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"btnDeleteFilter mr-3",attrs:{"color":"black","outlined":""},on:{"click":_vm.onClearSearch}},[_vm._v("Borrar")]),_c('v-btn',{staticClass:"btnApplyFilter",on:{"click":_vm.onSearch}},[_vm._v("Aplicar")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.equipment,"server-items-length":_vm.totalItems,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
      },"disable-sort":""},on:{"update:options":_vm.updatePagination,"click:row":_vm.getMachineByClickEvent},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[(
              _vm.permissions &&
              (_vm.permissions.view || _vm.permissions.edit || _vm.permissions.delete)
            )?_c('v-list',[(_vm.permissions.view)?_c('v-list-item',{on:{"click":function($event){return _vm.getMachineById(item.id, false)}}},[_c('v-list-item-title',[_vm._v("Ver detalle")])],1):_vm._e(),(_vm.permissions.edit)?_c('v-list-item',{on:{"click":function($event){return _vm.getMachineById(item.id, true)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1):_vm._e(),(_vm.permissions.delete)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_vm._v("Eliminar")])],1):_vm._e(),_c('v-spacer'),(_vm.showMakeTechReview(item))?_c('v-list-item',{on:{"click":function($event){return _vm.generateTechReview(item)}}},[_c('v-list-item-title',[_vm._v("Generar Revisón")])],1):_vm._e(),_c('v-spacer'),(_vm.showArchive(item))?_c('v-list-item',{on:{"click":function($event){return _vm.archiveItem(item)}}},[_c('v-list-item-title',[_vm._v("Archivar")])],1):_vm._e(),(_vm.showUnArchive(item))?_c('v-list-item',{on:{"click":function($event){return _vm.archiveItem(item)}}},[_c('v-list-item-title',[_vm._v("Desarchivar")])],1):_vm._e()],1):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"pill":"","color":_vm.chipColor(item.status),"text-color":_vm.textColor(item.status)}},[_c('strong',[_vm._v(_vm._s(item.status))])])]}}])})],1),_c('v-dialog',{model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Eliminar")]),_c('v-card-text',[_vm._v(" ¿Estás seguro de que deseas eliminar este elemento? ")]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.showDeleteModal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.confirmDelete}},[_vm._v("Eliminar")])],1)],1)],1),_c('v-dialog',{model:{value:(_vm.showArchiveModal),callback:function ($$v) {_vm.showArchiveModal=$$v},expression:"showArchiveModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Eliminar")]),_c('v-card-text',[_vm._v(" ¿Estás seguro de que deseas cambar el estatus de este elemento? ")]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.showArchiveModal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.updateStatus}},[_vm._v("Confirmar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }