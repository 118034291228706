var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card-title',{staticClass:"text-h5",attrs:{"primary-title":""}},[_vm._v(" Listado de Revisiones Técnicas ")]),_c('v-card-text',[_vm._v(" Aquí se muestran los procesos de revisiones técnicas para montacargas y dobladoras ")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.machineTypes,"item-value":"id","item-text":"name","label":"Tipo","single-line":"","clearable":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ingresa Serie o clave interna","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-checkbox',{attrs:{"label":"Mostrar finalizados","single-line":""},model:{value:(_vm.showFinalized),callback:function ($$v) {_vm.showFinalized=$$v},expression:"showFinalized"}})],1)],1),_c('v-row',{staticClass:"mb-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"btnDeleteFilter mr-3",attrs:{"color":"black","outlined":""},on:{"click":_vm.onClearSearch}},[_vm._v("Borrar")]),_c('v-btn',{staticClass:"btnApplyFilter",on:{"click":_vm.onSearch}},[_vm._v("Aplicar")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.techReviews,"server-items-length":_vm.totalItems,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
      },"disable-sort":""},on:{"update:options":_vm.updatePagination,"click:row":_vm.viewItem},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[(_vm.permissions && (_vm.permissions.edit || _vm.permissions.create || _vm.permissions.view))?_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.viewItem(item)}}},[_c('v-list-item-title',[_vm._v("Ver detalle")])],1),(!item.isFinalized && _vm.permissions.additionalAction1)?_c('v-list-item',{on:{"click":function($event){return _vm.makeTechReview(item)}}},[_c('v-list-item-title',[_vm._v("Realizar revisión")])],1):_vm._e(),(item.isFinalized && _vm.permissions.edit)?_c('v-list-item',{on:{"click":function($event){return _vm.onMarkAsFinalized(item.id)}}},[_c('v-list-item-title',[_vm._v("Reactivar revisión")])],1):(!item.isFinalized && _vm.permissions.edit)?_c('v-list-item',{on:{"click":function($event){return _vm.onMarkAsFinalized(item.id)}}},[_c('v-list-item-title',[_vm._v("Finalizar revisión")])],1):_vm._e()],1):_vm._e()],1)]}},{key:"item.isFinalized",fn:function(ref){
      var item = ref.item;
return [(item.isFinalized)?_c('v-chip',{attrs:{"pill":"","color":"#17ad37","text-color":"#ffffff"}},[_c('strong',[_vm._v("Finalizado")])]):_c('v-chip',{attrs:{"pill":"","color":"#fdc408","text-color":"#ffffff"}},[_c('strong',[_vm._v("En curso")])])]}}])})],1),_c('v-dialog',{staticClass:"pa-3",attrs:{"max-width":"1200px"},model:{value:(_vm.showConfirmModal),callback:function ($$v) {_vm.showConfirmModal=$$v},expression:"showConfirmModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Finalizar revisión")]),_c('v-card-text',[_vm._v(" ¿Estás seguro de que deseas finalizar la revisión? ")]),_c('v-card-actions',[_c('v-spacer'),_c('single-btn',{staticClass:"mr-3",attrs:{"isPrimary":false,"buttonText":"Cancelar","clickHandler":function () { return (_vm.showConfirmModal = false); }}}),_c('v-btn',{attrs:{"color":"#fdc408"},on:{"click":_vm.markAsFinalized}},[_c('span',{staticStyle:{"color":"#ffffff"}},[_vm._v("Confirmar")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }