<template>
  <div>
    <v-card class="pa-4">
      <!-- Filtros y Búsqueda -->
      <v-row>
        <!-- TEXT CARD -->
        <v-col cols="12" md="4">
          <v-card-title primary-title class="text-h5">
            Detalle de revisión
          </v-card-title>
        </v-col>
      </v-row>
      <!-- info de maquina -->
      <v-row v-if="techReview">
        <v-col cols="0" md="1" lg="3"></v-col>
        <v-col v-if="techReview.techReview.machine.image" cols="12" md="5" lg="3">
          <v-img
            :src="techReview.techReview.machine.image"
            style="max-width: 300px"
          ></v-img>
        </v-col>
        <v-col cols="12" md="5" lg="3">
          <v-row>
            <v-col>
              <strong>Marca: </strong> {{ techReview.techReview.machine.brand }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>Tipo: </strong> {{ techReview.techReview.machine.type }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>Modelo: </strong> {{ techReview.techReview.machine.model }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>Clave interna: </strong>
              {{ techReview.techReview.machine.innerId }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>Serie: </strong>
              {{ techReview.techReview.machine.serialNumber }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center">
              <v-select
                v-model="selectPhase"
                :items="phases"
                single-line
                label="Seleccionar"
                item-value="id"
                item-text="name"
              ></v-select>
            </v-col>
            <v-col class="d-flex align-center">
              <v-btn v-if="selectPhase !== this.techReview.currentPhase" class="btnApplyFilter" @click="updatePhase" :disabled="loading">
                Actualizar
              </v-btn>
            </v-col>
            <v-col class="d-flex align-center">
              <v-progress-circular v-if="loading" indeterminate>
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="0" md="1" lg="3"></v-col>
      </v-row>

      <v-row class="mt-6 mb-6">
        <v-tabs :value="phaseIndex" background-color="transparent" class="text-left" style="background-color: rgba(253, 196, 8, .2);">
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-for="phase in phases" :key="phase.id" @change="onPhaseChange(phase.id)" style="background-color: rgba(253, 196, 8, .2);">
            <span class="ms-1">{{phase.name}}</span>
          </v-tab>
        </v-tabs>
      </v-row>

      <!-- Formulario de la sección -->
      <v-row v-for="item in Object.keys(formInfo)" :key="item">
        <v-col>
          <v-row>
            <v-col>
              <strong>{{ item }}</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="field in formInfo[item]"
              :key="field.id"
              lg="2"
              md="6"
              cols="12"
            >
              <div v-if="field.type === ActionTypeEnum.BOOL">
                <v-checkbox
                  v-model="field.activityValue"
                  :label="field.activityName"
                  single-line
                  class="shrink mr-0 mt-0"
                  @click="onCheckChange(field)"
                  :disabled="activePhase !== techReview.currentPhase"
                ></v-checkbox>
              </div>
              <div v-else-if="field.type === ActionTypeEnum.INPUT">
                <v-text-field
                  v-model="field.activityValue"
                  :label="field.activityName"
                  @click="onCheckChange(field)"
                  :disabled="activePhase !== techReview.currentPhase"
                ></v-text-field>
              </div>
              <div v-else-if="field.type === ActionTypeEnum.PERCENTAGE">
                <v-text-field
                  v-model="field.activityValue"
                  :label="field.activityName"
                  type="number"
                  model-value="100.00"
                  suffix="%"
                  :rules="rules"
                  @click="onCheckChange(field)"
                  :disabled="activePhase !== techReview.currentPhase"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="techReview && activePhase === techReview.currentPhase">
        <v-col>
          <v-textarea
            v-model="comment"
            placeholder="Inserta nota adicional"
            label="Nota"
          >
          </v-textarea>
        </v-col>
      </v-row>
        </v-col>
      </v-row>



      <v-row class="pr-5 mb-8" v-if="techReview && activePhase === techReview.currentPhase">
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          class="btnDeleteFilter mr-3"
          outlined
          @click="onReturn"
          >Regresar</v-btn
        >
        <v-btn class="btnApplyFilter" @click="onSave">Actualizar</v-btn>
      </v-row>
    </v-card>
  </div>
</template>

<style>
.background {
  background-image: url("../../assets/img/welcome.jpg");
  background-size: cover;
  background-position: center;
  height: 30em;
  position: relative;
  /* Añade posición relativa para .background */
}

.overlay {
  position: absolute;
  /* Cambia la posición a absoluta */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del contenedor .background */
  height: 100%;
  /* Ocupa toda la altura del contenedor .background */
  display: flex;
  /* Hace que los elementos se alineen en el centro */
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(122, 118, 57, 0.619);
  /* Ajusta el color y la opacidad según sea necesario */
}

.welcome-text,
.user-text {
  color: white;
  margin: 0;
  /* Elimina los márgenes predeterminados */
  display: inline-block;
  /* Cambia el comportamiento a inline-block */
}

.btnDeleteFilter {
  border: 1px solid #000;
  /* borde negro */
  border-radius: 10px;
  /* bordes redondeados */
  color: #000;
  /* texto negro */
  background-color: white;
  /* fondo transparente */
  padding: 10px 25px;
  /* relleno horizontal y vertical */
}

.btnApplyFilter {
  border-radius: 10px;
  /* bordes redondeados */
  color: #fff !important;
  /* texto blanco */
  background-color: #37474f;
  /* color de fondo oscuro */
  padding: 10px 25px;
  /* relleno horizontal y vertical */
  background-image: linear-gradient(to right, #5f5f5f, #000000);
  /* degradado de ejemplo */
}
</style>
<script>
import axios from "axios";
import ReviewTimeline from "@/frimar/components/ReviewTimeline.vue";
import ActionTypeEnum from "../../enums/modules/ActionTypeEnum";

export default {
  name: "TechReviewForm",
  computed: {
    ActionTypeEnum() {
      return ActionTypeEnum;
    },
    phaseIndex() {
      if (this.activePhase) {
        let index = this.phases.findIndex(x => x.id === this.activePhase);

        if(index >= 0){
          return index;
        }
      }

      return null;
    }
  },
  components: { ReviewTimeline },
  data() {
    return {
      techReview: null,
      formInfo: [],
      comment: null,
      rules: [
        (value) => value <= 100 || "No puede ser mayor de 100",
        (value) => value >= 0 || "No puede ser menor de 0",
      ],
      changes: [],
      activePhase: null,
      phases: [],
      selectPhase: null,
      loading: false,
    };
  },
  methods: {
    getTechReview() {
      axios
        .get(
          process.env.VUE_APP_APIURL +
            "techReviewInfo/?id=" +
            this.$route.params.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then((response) => {
          this.techReview = response.data;
          this.formInfo = this.groupData(this.techReview.info);
          this.activePhase = this.techReview.currentPhase;
          this.selectPhase = this.techReview.currentPhase;
        });
    },
    groupData(data) {
      return Object.groupBy(data, (item) => {
        return item.sectionName;
      });
    },
    onReturn() {
      this.$router.push({ name: "TechnicalRevision" });
    },
    onSave() {
      let percentagesInputs = [];
      Object.keys(this.formInfo).forEach((value) => {
        this.formInfo[value].forEach((action) => {
          if (action.type === ActionTypeEnum.PERCENTAGE)
            percentagesInputs.push(action);
        });
      });

      if (percentagesInputs.length > 0) {
        percentagesInputs = percentagesInputs.some(
          (action) => action.activityValue < 0 || action.activityValue > 100
        );

        if (percentagesInputs) {
          this.$swal({
            title: "Error en porcentajes",
            text: "Los porcentajes deben tener un rango entre 0 y 100 inclusive",
            icon: "error",
          });
          return;
        }
      }

      axios
        .put(
          process.env.VUE_APP_APIURL +
            "techReviewInfo/?id=" +
            this.$route.params.id,
          this.buildUpdateObj(),
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then((response) => {
          this.getTechReview();
          this.comment = null;
          this.$router.push("/app/techReviewDetail/" + this.$route.params.id);
        });
    },
    buildUpdateObj() {
      var body = {
        phaseId: this.techReview.currentPhase,
        info: [],
        comment: this.comment,
        changes: this.changes.map(obj => obj.activityName).join(', ')
      };

      Object.keys(this.formInfo).forEach((key) => {
        this.formInfo[key].forEach((x) => body.info.push(x));
      });

      return body;
    },
    onCheckChange(item) {
      const index = this.changes.findIndex((obj) => obj.id === item.id);

      // If the index is found (-1 means not found), remove the object at that index
      if (index !== -1) {
        this.changes.splice(index, 1);
      } else {
        this.changes.push(item);
      }
    },
    getPhases() {
      axios
        .get(process.env.VUE_APP_APIURL + "phases/", {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.phases = response.data;
        });
    },
    onPhaseChange(id) {
      axios
        .get(
          process.env.VUE_APP_APIURL +
            "techReviewInfo/?id=" +
            this.$route.params.id +
            "&phaseId=" +
            id ,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then((response) => {
          this.techReview = response.data;
          this.formInfo = this.groupData(this.techReview.info);
          this.activePhase = id;
        });
    },
    updatePhase() {
      this.loading = true;
      axios
        .put(
          process.env.VUE_APP_APIURL +
            "dashboard/?id=" +
            this.$route.params.id +
            "&phaseId=" +
            this.selectPhase,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.$swal({
            title: "Actualizado",
            text: "Se actualizó con éxito la fase.",
            icon: "success",
          });
          this.getTechReview();
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            title: "No fue posible actualizar",
            text: err.response.data.message
              ? err.response.data.message
              : "Por favor, completa todos los campos requeridos.",
            icon: "error",
          });
          this.getTechReview();
        });
    },
  },
  mounted() {
    this.getTechReview();
    this.getPhases();
  },
};
</script>
