<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative"
        :style="`background-image: url(${require('../../assets/img/frimar/loginBrackground.jpg')}); background-size: cover; background-position: 50%; height: 30vh;`"
      >
        <div
          class="overlay"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
          "
        ></div>
        <v-container style="position: relative; z-index: 1">
          <v-row
            class="d-flex justify-center align-center"
            style="height: 100%; z-index: 3"
          >
            <v-col cols="12" class="text-center" style="margin-top: -5rem">
              <h1 class="text-white display-3" style="font-weight: bold">
                Bienvenido
              </h1>
              <h2 class="text-white subtitle-1">
                Inicia sesión para ingresar a la plataforma de trabajo
              </h2>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container class="mt-n16 pb-0">
        <v-row class="justify-center d-flex">
          <v-col lg="4" md="7" class="mx-auto">
            <v-card
              class="card-shadow border-radius-xl"
              style="z-index: 2; position: relative"
              elevation="4"
            >
              <div class="card-padding text-center">
                <h5 class="text-h5 text-typo font-weight-bold">
                  Iniciar sesión
                </h5>
              </div>
              <div class="card-padding">
                <v-card-text class="pl-1 font-weight-bold">Nombre de usuario</v-card-text>
                <v-text-field
                  v-model="user.email"
                  :rules="[rules.required]"
                  hide-details
                  outlined
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  light
                  height="40"
                  placeholder="ej. ingeniero@frimar.com"
                  class="
                    input-style
                    font-size-input
                    border border-radius-md
                    placeholder-lighter
                    text-color-light
                    mb-4
                  "
                >
                </v-text-field>
                <v-card-text class="pl-1 font-weight-bold"
                  >Contraseña</v-card-text
                >
                <v-text-field
                  v-model="user.password"
                  :rules="[rules.required]"
                  hide-details
                  outlined
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  light
                  height="40"
                  placeholder="Contraseña"
                  class="
                    input-style
                    font-size-input
                    border border-radius-md
                    placeholder-lighter
                    text-color-light
                    mb-4
                  "
                  type="password"
                  @keyup.enter="login"
                >
                </v-text-field>
                <v-btn
                  @click="login"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-uppercase
                    btn-default
                    py-2
                    px-6
                    me-2
                    mt-6
                    mb-2
                    w-100
                    btn-gradient
                  "
                  small
                  >Iniciar sesión</v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<style>
.btn-gradient {
  background: rgb(220, 236, 27);
  background: linear-gradient(
    124deg,
    rgba(220, 236, 27, 1) 0%,
    rgba(161, 172, 13, 1) 75%,
    rgba(100, 101, 101, 1) 100%
  );
  color: white;
}

.btn-gradient:hover {
  background: rgb(220, 236, 27);
  background: linear-gradient(
    124deg,
    rgba(220, 236, 27, 1) 0%,
    rgba(161, 172, 13, 1) 75%,
    rgba(100, 101, 101, 1) 100%
  );
}
</style>

<script>
import axios from "axios";

export default {
  name: "login",
  components: {},
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      rules: {
        required: (value) => !!value || "Requerido.",
        email: (value) => {
          const pattern =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico inválido.";
        },
      },
    };
  },
  methods: {
    validate() {
      if (
        this.rules.required(this.user.email) !== true ||
        this.rules.required(this.user.password) !== true
      ) {
        this.showFieldsRequiredAlert();
        return false;
      }
      return true;
    },
    login() {
      if (!this.validate()) return;
      this.sendLoginRequest();
    },
    sendLoginRequest() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "project/token/", {
          username: this.user.email,
          password: this.user.password,
        })
        .then((response) => {
          this.getDrawerData(response.data.access);
          this.$store.dispatch("auth/setAccessToken", response.data.access);
          this.$store.dispatch("auth/setRefreshToken", response.data.refresh);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.showInvalidCredentialsAlert();
          } else {
            this.$swal({
              title: "Error",
              text: "Ha ocurrido un error. Por favor, intenta de nuevo más tarde.",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        });
    },
    showFieldsRequiredAlert() {
      this.$swal({
        title: "Campos Requeridos",
        text: "Por favor, completa todos los campos requeridos.",
        icon: "warning",
        confirmButtonText: "Entendido",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
      });
    },
    showInvalidCredentialsAlert() {
      this.$swal({
        title: "Credenciales Incorrectas",
        text: "El correo electrónico o la contraseña no son correctos. Por favor, intenta de nuevo.",
        icon: "error",
        confirmButtonText: "Intentar de Nuevo",
        customClass: {
          confirmButton: "btn bg-gradient-danger",
        },
      });
    },
    getDrawerData(token) {
      axios
        .get(process.env.VUE_APP_APIURL + "drawer/", {headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {
          this.$store.dispatch("sections/setDrawerSections", response.data);
          this.$router.push("/app/dashboard");
        })
        .catch((error) => {
          console.log(error)
        });
    },
  },
};
</script>
