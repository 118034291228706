/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================
* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all
* copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import '@mdi/font/css/materialdesignicons.css'
import dotenv from 'dotenv';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import store from './store/index.js'; // Asegúrate de que la ruta sea correcta

dotenv.config(); 

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

new Vue({
  router,
  vuetify,
  store, // Vuex store
  render: (h) => h(App),
}).$mount("#app");
