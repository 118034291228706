const MachineStatusEnum = Object.freeze({
  ACCOUNTANT: "Contabilidad",
  TECH_REVIEW: "Revisión",
  SALES: "Ventas",
  MANAGER: "Gerencia",
  IT: "Sistemas",
  ON_SALE: "En venta",
  ARCHIVED: "Archivado",
});

export default MachineStatusEnum;
