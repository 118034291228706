<template>
  <div>
    <v-card class="pa-4">
      <!-- Filtros y Búsqueda -->
      <v-row>
        <!-- TEXT CARD -->
        <v-col cols="12" md="4">
          <v-card-title primary-title class="text-h5">
            Listado de Equipos
          </v-card-title>
          <v-card-text>
            Aqui se muestran los equipos dados de alta en el sistema
          </v-card-text>
        </v-col>

        <!-- FILTER OPTIONS -->
        <v-col cols="12" md="8">
          <v-row class="pr-5 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              v-if="permissions && permissions.create"
              color="black"
              class="btnDeleteFilter mr-3"
              outlined
              @click="createItem"
              >Crear Equipo</v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                v-model="selectedType"
                :items="types"
                item-value="id"
                item-text="name"
                label="Tipo"
                single-line
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="status"
                :items="statusList"
                label="Estatus"
                single-line
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Ingresa Serie o clave interna"
                single-line
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pr-5 mb-8">
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              class="btnDeleteFilter mr-3"
              outlined
              @click="onClearSearch"
              >Borrar</v-btn
            >
            <v-btn class="btnApplyFilter" @click="onSearch">Aplicar</v-btn>
          </v-row>
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-data-table
        :headers="headers"
        :items="equipment"
        class="elevation-1"
        :server-items-length="totalItems"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
        }"
        disable-sort
        @click:row="getMachineByClickEvent"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="black" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list
              v-if="
                permissions &&
                (permissions.view || permissions.edit || permissions.delete)
              "
            >
              <v-list-item
                v-if="permissions.view"
                @click="getMachineById(item.id, false)"
              >
                <v-list-item-title>Ver detalle</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="permissions.edit"
                @click="getMachineById(item.id, true)"
              >
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="permissions.delete" @click="deleteItem(item)">
                <v-list-item-title>Eliminar</v-list-item-title>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-list-item
                v-if="showMakeTechReview(item)"
                @click="generateTechReview(item)"
              >
                <v-list-item-title>Generar Revisón</v-list-item-title>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-list-item v-if="showArchive(item)" @click="archiveItem(item)">
                <v-list-item-title>Archivar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="showUnArchive(item)"
                @click="archiveItem(item)"
              >
                <v-list-item-title>Desarchivar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            pill
            :color="chipColor(item.status)"
            :text-color="textColor(item.status)"
          >
            <strong>{{ item.status }}</strong>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modales -->
    <!-- Modal para eliminar -->
    <v-dialog v-model="showDeleteModal">
      <v-card>
        <v-card-title>Eliminar</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas eliminar este elemento?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showDeleteModal = false">Cancelar</v-btn>
          <v-btn color="red" @click="confirmDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para archivar -->
    <v-dialog v-model="showArchiveModal">
      <v-card>
        <v-card-title>Eliminar</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas cambar el estatus de este elemento?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showArchiveModal = false">Cancelar</v-btn>
          <v-btn color="green" @click="updateStatus">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import { MachineStatusEnum, ViewsEnum } from "@/enums";

export default {
  name: "Equiptments",
  components: {},
  data() {
    return {
      selectedType: null,
      showEditModal: false,
      showDeleteModal: false,
      showArchiveModal: false,
      showCreateModal: false,
      showDetailModal: false,
      page: 1,
      pageSize: 10,
      totalItems: 0,
      search: "",
      status: null,
      statusList: Object.values(MachineStatusEnum),
      types: [],
      valid: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        youtubeUrl: (value) => {
          if (!value) return true;
          const urls = value.split(",");
          const pattern =
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
          return (
            urls.every((url) => pattern.test(url.trim())) ||
            "Una o más URLs son inválidas."
          );
        },
      },
      headers: [
        { text: "Marca", value: "brand" },
        { text: "Modelo", value: "model" },
        { text: "Tipo", value: "typeName" },
        { text: "Clave interna", value: "innerId" },
        { text: "Número de Serie", value: "serialNumber" },
        { text: "Estatus", value: "status", sortable: false },
        { text: "Ultima edición", value: "editedBy", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      equipment: [],
      currentItem: {
        id: null,
        created: "",
        modified: "",
        deleted: null,
        brand: "",
        type: "",
        model: "",
        innerId: "",
        serialNumber: "",
        youtubeUrl: "",
        images: null,
      },
      editingItem: {
        id: null,
        created: "",
        modified: "",
        deleted: null,
        brand: "",
        type: "",
        model: "",
        innerId: "",
        serialNumber: "",
        youtubeUrl: "",
      },
      newItem: {
        brand: "",
        type: "",
        model: "",
        innerId: "",
        serialNumber: "",
        youtubeUrl: "",
      },
      validCreation: false,
      images: [],
      listOptions: {},
      permissions: null,
    };
  },
  computed: {
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
    showMakeTechReview() {
      return (item) => {
        return !item.techReview && item.status === MachineStatusEnum.SALES;
      };
    },
    showArchive() {
      return (item) => {
        return item.status === MachineStatusEnum.ON_SALE;
      };
    },
    showUnArchive() {
      return (item) => {
        return item.status === MachineStatusEnum.ARCHIVED;
      };
    },
    textColor() {
      return (status) => {
        if (status === MachineStatusEnum.ACCOUNTANT) {
          return "#FFFFFF";
        } else if (status === MachineStatusEnum.TECH_REVIEW) {
          return "#000000";
        } else if (status === MachineStatusEnum.SALES) {
          return "#000000";
        } else if (status === MachineStatusEnum.MANAGER) {
          return "#FFFFFF";
        } else if (status === MachineStatusEnum.IT) {
          return "#FFFFFF";
        } else if (status === MachineStatusEnum.ON_SALE) {
          return "#FFFFFF";
        } else if (status === MachineStatusEnum.ARCHIVED) {
          return "#FFFFFF";
        }
      };
    },
    chipColor() {
      return (status) => {
        if (status === MachineStatusEnum.ACCOUNTANT) {
          return "#BD2D87";
        } else if (status === MachineStatusEnum.TECH_REVIEW) {
          return "#B3FFFC";
        } else if (status === MachineStatusEnum.SALES) {
          return "#D1E000";
        } else if (status === MachineStatusEnum.MANAGER) {
          return "#57A550";
        } else if (status === MachineStatusEnum.IT) {
          return "#2A5460";
        } else if (status === MachineStatusEnum.ON_SALE) {
          return "#2878CC";
        } else if (status === MachineStatusEnum.ARCHIVED) {
          return "#002869";
        }
      };
    },
  },
  methods: {
    viewItem(item) {
      this.showDetailModal = true;
      this.currentItem = {
        ...item,
        youtubeUrls: item.youtubeUrl ? item.youtubeUrl.split(",") : [],
      };
    },
    editItem(item) {
      this.showEditModal = true;

      this.editingItem = JSON.parse(JSON.stringify(item));
    },
    deleteItem(item) {
      this.currentItem = item;
      this.showDeleteModal = true;
    },
    archiveItem(item) {
      this.currentItem = item;
      this.showArchiveModal = true;
    },
    openEditModalFromDetails() {
      this.editingItem = { ...this.currentItem };
      this.showDetailModal = false;
      this.$nextTick(() => {
        console.log(this.editingItem);
        this.showEditModal = true;
      });
    },
    createItem() {
      this.$router.push("/app/new/machine");
    },
    addUrlField() {
      this.equipmentDetails.gallery.push("");
    },
    addUrlFieldEdit() {
      this.actionEquipment.Galeria.push("");
    },
    removeUrlField(index) {
      this.actionEquipment.Galeria.splice(index, 1);
    },
    confirmDelete() {
      if (!this.currentItem) {
        this.showAlert(
          "info",
          "Formulario incompleto",
          "No se ha seleccionado ningún equipo para eliminar."
        );
        return;
      }
      const item = this.equipment.find((e) => e === this.currentItem);
      if (item) {
        axios
          .delete(process.env.VUE_APP_APIURL + "machine/?id=" + item.id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          })
          .then(() => {
            this.currentItem = {
              id: null,
              created: "",
              modified: "",
              deleted: null,
              brand: "",
              type: "",
              model: "",
              innerId: "",
              serialNumber: "",
              youtubeUrl: "",
              images: null,
            };
            this.getMachines();
          })
          .catch((e) =>
            this.showAlert(
              "error",
              "Ocurrió un error",
              "No fue posible eliminar el equipo."
            )
          );
        this.showAlert(
          "success",
          "Equipo eliminado",
          "Equipo eliminado con éxito."
        );
      }

      this.showDeleteModal = false;
    },
    getMachineById(id, isEdit) {
      if (isEdit) {
        if (this.permissions && this.permissions.edit) {
          this.$router.push("/app/machine/edit/" + id);
          return;
        }
      } else {
        this.$router.push("/app/machine/" + id);
        return;
      }
    },
    getMachineByClickEvent(row) {
      if (this.permissions && this.permissions.view) {
        this.$router.push("/app/machine/" + row.id);
      }
    },
    getMachines() {
      let fullUrl =
        process.env.VUE_APP_APIURL +
        "machines/?page=" +
        this.page +
        "&items=" +
        this.pageSize;
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.totalItems = response.data.total;
          this.equipment = response.data.items.map((machine) => ({
            ...machine,
            innerId: machine.innerId,
            youtubeUrl: machine.youtubeUrl,
          }));
        });
    },
    onSearch() {
      this.page = 1;
      let fullUrl =
        process.env.VUE_APP_APIURL +
        "machines/?page=" +
        this.page +
        "&items=" +
        this.pageSize;

      if (this.search) {
        fullUrl += "&search=" + this.search;
      }

      if (this.selectedType) {
        fullUrl += "&type=" + this.selectedType;
      }

      if (this.status) {
        fullUrl += "&status=" + this.status;
      }

      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.totalItems = response.data.total;
          this.equipment = response.data.items.map((machine) => ({
            ...machine,
            innerId: machine.innerId,
            youtubeUrl: machine.youtubeUrl,
          }));
        });
    },
    onClearSearch() {
      this.page = 1;
      this.search = null;
      this.selectedType = null;
      this.getMachines();
    },
    getMachineTypes() {
      let fullUrl = process.env.VUE_APP_APIURL + "machineTypes/";
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.types = response.data;
        });
    },
    generateTechReview(item) {
      axios
        .post(process.env.VUE_APP_APIURL + "techReview/", item, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.$router.push("/app/techReviewForm/" + response.data.id);
        })
        .catch((error) => {
          console.error("Error al actualizar el equipo:", error);
          let message = "Ocurrió un problema al actualizar el equipo";

          if (error.response?.data?.message) {
            message = error.response.data.message;
          }

          this.showAlert("error", "Error", message);
        });
    },
    showAlert(alertType, title, text) {
      switch (alertType) {
        case "error":
          this.$swal({
            title: title,
            text: text,
            icon: "error",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-danger",
            },
          });
          break;
        case "info":
          this.$swal({
            title: title,
            text: text,
            icon: "info",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-info",
            },
          });
          break;
        default:
          // Caso para "success"
          this.$swal({
            title: title,
            text: text,
            icon: "success",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
          });
          break;
      }
    },
    updatePagination(opts) {
      if (this.pageSize !== opts.itemsPerPage) {
        this.pageSize = opts.itemsPerPage;
        this.page = 1;
      }
      this.page = opts.page;
      this.getMachines();
    },
    getPermissionsForView() {
      let currentView = this.drawerItems.find(
        (view) => view.value === ViewsEnum.EQUIPMENT
      );
      if (currentView) {
        this.permissions = currentView;
      } else {
        this.$router.go(-1);
      }
    },
    updateStatus() {
      let item = this.currentItem;
      let status = {
        status: MachineStatusEnum.ARCHIVED,
      };

      if (item.status === MachineStatusEnum.ARCHIVED) {
        status.status = MachineStatusEnum.ON_SALE;
      }

      axios
        .put(
          process.env.VUE_APP_APIURL + "machineStatus/?id=" + item.id,
          status,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then(() => {
          this.currentItem = {
            id: null,
            created: "",
            modified: "",
            deleted: null,
            brand: "",
            type: "",
            model: "",
            innerId: "",
            serialNumber: "",
            youtubeUrl: "",
            images: null,
          };
          this.getMachines();
        })
        .catch((error) => {
          console.error("Error al archivar el equipo:", error);
          let message = "Ocurrió un problema al archivar el equipo";

          if (error.response?.data?.message) {
            message = error.response.data.message;
          }

          this.showAlert("error", "Error", message);
        });

      this.showArchiveModal = false;
    },
  },
  mounted() {
    this.getMachines();
    this.getMachineTypes();
    this.getPermissionsForView();
  },
};
</script>
