<template>
  <div>
    <v-card class="pa-4">
      <!-- Filtros y Búsqueda -->
      <v-row>
        <!-- TEXT CARD -->
        <v-col cols="12" md="4">
          <v-card-title primary-title class="text-h5">
            Lista de Usuarios
          </v-card-title>
          <v-card-text>
            Aquí se muestran los usuarios registrados, puedes crear nuevos
            usuarios y buscar por username.
          </v-card-text>
        </v-col>
        <!-- FILTER OPTIONS -->
        <v-col cols="12" md="8">
          <v-row class="pr-5 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              v-if="permissions && permissions.create"
              color="black"
              class="btnDeleteFilter mr-3"
              outlined
              @click="
                () => {
                  this.showCreateModal = true;
                }
              "
              >Crear Usuario</v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selectedType"
                :items="types"
                label="Seleccionar"
                single-line
                clearable
                item-value="id"
                item-text="name"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pr-5 mb-8">
            <v-spacer></v-spacer>
            <single-btn
              :isPrimary="false"
              buttonText="Borrar"
              class="mr-1"
              :clickHandler="onClearSearch"
            ></single-btn>
            <single-btn
              :isPrimary="true"
              buttonText="Aplicar"
              :clickHandler="onSearch"
            ></single-btn>
          </v-row>
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-data-table
        :headers="headers"
        :items="users"
        class="elevation-0"
        :server-items-length="totalItems"
        @update:options="updatePagination"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
        }"
        disable-sort
      >
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="black" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list
              v-if="
                permissions &&
                (permissions.edit ||
                  permissions.delete ||
                  permissions.additionalAction1)
              "
            >
              <v-list-item v-if="permissions.edit" @click="editItem(item)">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="permissions.additionalAction1"
                @click="onChangePassword(item)"
              >
                <v-list-item-title>Cambiar contraseña</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="permissions.delete" @click="deleteUser(item)">
                <v-list-item-title>Eliminar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal para crear -->
    <v-dialog v-model="showCreateModal" max-width="1200px">
      <v-card>
        <v-card-title primary-title class="text-h5"
          >Creación de Usuario</v-card-title
        >
        <v-form v-model="valid">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nombre"
                    v-model="newUser.first_name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Apellido"
                    v-model="newUser.last_name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Nombre de usuario"
                    v-model="newUser.username"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Contraseña"
                    v-model="newUser.password"
                    :rules="[rules.required]"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Repetir Contraseña"
                    v-model="passwordConfirm"
                    :rules="[rules.required, rules.passwordMatch]"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-select
                :items="types"
                label="Tipo"
                item-value="id"
                item-text="name"
                v-model="newUser.userType"
                :rules="[rules.required]"
              ></v-select>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <single-btn
            :isPrimary="false"
            buttonText="Cancelar"
            class="mr-1"
            :clickHandler="() => (showCreateModal = false)"
          ></single-btn>
          <single-btn
            :isPrimary="true"
            buttonText="Crear"
            :clickHandler="
              () => {
                valid && createNewItem();
              }
            "
          ></single-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para editar -->
    <v-dialog v-model="showEditModal" max-width="1200px">
      <v-card>
        <v-card-title primary-title class="text-h5"
          >Edición de Usuario</v-card-title
        >
        <v-card-text>
          <v-form v-model="valid">
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nombre"
                    v-model="selectedUser.first_name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Apellido"
                    v-model="selectedUser.last_name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Nombre de usuario"
                    v-model="selectedUser.username"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="types"
                    label="Tipo"
                    item-value="id"
                    item-text="name"
                    v-model="selectedUser.userType"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <single-btn
            :isPrimary="false"
            buttonText="Cancelar"
            class="mr-1"
            :clickHandler="() => (showEditModal = false)"
          ></single-btn>
          <single-btn
            :isPrimary="true"
            buttonText="Editar"
            :clickHandler="() => valid && updateUser()"
          ></single-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Eliminar -->
    <v-dialog v-model="showDeleteModal" max-width="1200px" class="pa-3">
      <v-card>
        <v-card-title>Eliminar</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas eliminar este elemento?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <single-btn
            :isPrimary="false"
            :clickHandler="() => (showDeleteModal = false)"
            class="mr-3"
            button-text="Cancelar"
          ></single-btn>
          <v-btn color="red" @click="confirmDelete"
            ><span style="color: #ffffff">Eliminar</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para cambiar password -->
    <v-dialog v-model="showChangePassword" max-width="1200px">
      <v-card>
        <v-card-title primary-title class="text-h5"
          >Actualizar contraseña</v-card-title
        >
        <v-form v-model="valid">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Contraseña"
                    v-model="changePasswordValue"
                    :rules="[rules.required]"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Repetir Contraseña"
                    v-model="changePasswordConfirmValue"
                    :rules="[rules.required, rules.passwordMatch]"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <single-btn
            :isPrimary="false"
            buttonText="Cancelar"
            class="mr-1"
            :clickHandler="() => (showChangePassword = false)"
          ></single-btn>
          <single-btn
            :isPrimary="true"
            buttonText="Actualizar"
            :clickHandler="
              () => {
                valid && changePassword();
              }
            "
          ></single-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SingleBtn from "../components/singleBtn.vue";
import axios from "axios";
import { ViewsEnum } from "@/enums";

export default {
  name: "Users",
  components: {
    SingleBtn,
  },
  data() {
    return {
      selectedType: null,
      showEditModal: false,
      showDeleteModal: false,
      showCreateModal: false,
      showDetailModal: false,
      showChangePassword: false,
      totalItems: 0,
      page: 1,
      pageSize: 10,
      search: "",
      types: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Nombre", value: "first_name" },
        { text: "Apellido", value: "last_name" },
        { text: "Nombre de usuario", value: "username" },
        { text: "Tipo", value: "typeName" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      actions: { ver: true, editar: true, eliminar: true },
      users: [],
      newUser: {
        first_name: "",
        last_name: "",
        username: "",
        password: "",
        userType: "",
      },
      selectedUser: {
        first_name: "",
        last_name: "",
        username: "",
        password: "",
        userType: "",
      },
      passwordConfirm: "",
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        email: (value) =>
          /.+@.+\..+/.test(value) || "Correo electrónico inválido.",
        passwordMatch: () =>
          this.newUser.password === this.passwordConfirm ||
          "Las contraseñas no coinciden.",
      },
      valid: false,
      permissions: null,
      changePasswordValue: null,
      changePasswordConfirmValue: null,
    };
  },
  computed: {
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
  },
  methods: {
    editItem(item) {
      this.showEditModal = true;
      this.selectedUser = Object.assign({}, item);
    },
    deleteUser(item) {
      this.showDeleteModal = true;
      this.currentItem = item; // Guarda el item actual en una nueva propiedad `currentItem`
    },
    onChangePassword(item) {
      this.showChangePassword = true;
      this.currentItem = item; // Guarda el item actual en una nueva propiedad `currentItem`
    },
    createNewItem() {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const url = `${baseUrl}app/v1.0/user/`;
      const userData = {
        ...this.newUser,
        status: "1",
      };

      if (this.newUser.password !== this.passwordConfirm) {
        this.showAlert(
          "error",
          "Las contraseñas no coinciden",
          "No se pudo crear el usuario ya que las contraseñas no coinciden"
        );
        return;
      }

      axios
        .post(url, userData, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then(() => {
          this.showAlert(
            "success",
            "Usuario creado",
            "Se creo correctamente el nuevo usuario"
          );
          this.showCreateModal = false;
          this.getUsers();
          this.newUser = {
            first_name: null,
            last_name: null,
            username: null,
            password: null,
            userType: null,
          };
          this.passwordConfirm = null;
        })
        .catch((error) => {
          console.error("Error al crear el usuario:", error);
          this.showAlert(
            "error",
            "Ocurrió un problema",
            "Ocurrio un problema al crear el usuario, revise los campos"
          );
        });
    },

    updateUser() {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const url = `${baseUrl}/app/v1.0/user/?id=${this.selectedUser.id}`;
      const userData = {
        first_name: this.selectedUser.first_name,
        last_name: this.selectedUser.last_name,
        username: this.selectedUser.username,
        status: "1",
        userType: this.selectedUser.userType,
      };

      axios
        .put(url, userData, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then(() => {
          this.showAlert(
            "success",
            "Usuario actualizado",
            "El usuario se actualizo correctamente"
          );
          this.showEditModal = false; // Cierra el modal
          this.getUsers(); // Actualiza la lista de usuarios si es necesario
        })
        .catch((error) => {
          console.error("Error al actualizar el usuario:", error);
          this.showAlert(
            "error",
            "Ocurrió un problema",
            "Ocurrio un problema al tratar de actualizar el usuario"
          );
        });
    },

    confirmDelete() {
      if (!this.currentItem || !this.currentItem.id) {
        this.showAlert(
          "info",
          "Formulario incompleto",
          "Error: No se ha seleccionado un usuario válido para eliminar."
        );
        this.currentItem = null;
        return;
      }

      axios
        .delete(
          process.env.VUE_APP_APIURL + "user/?id=" + this.currentItem.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then(() => {
          this.showAlert(
            "success",
            "Usuario eliminado",
            "Usuario eliminado con éxito."
          );
          this.currentItem = null;
          this.showDeleteModal = false;
          this.getUsers(); // Actualiza
        })
        .catch(() =>
          this.showAlert(
            "error",
            "Ocurrió un error",
            "No fue posible eliminar al usuario."
          )
        );
    },
    getUserTypes() {
      let fullUrl = process.env.VUE_APP_APIURL + "userTypes/";
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.types = response.data;
        });
    },
    getUsers() {
      let fullUrl =
        process.env.VUE_APP_APIURL +
        "users/?page=" +
        this.page +
        "&items=" +
        this.pageSize;
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.users = response.data.items;
          this.totalItems = response.data.total;
        });
    },
    onSearch() {
      this.page = 1;
      let fullUrl =
        process.env.VUE_APP_APIURL +
        "users/?page=" +
        this.page +
        "&items=" +
        this.pageSize;

      if (this.search) {
        fullUrl += "&search=" + this.search;
      }

      if (this.selectedType) {
        fullUrl += "&type=" + this.selectedType;
      }

      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.users = response.data.items;
          this.totalItems = response.data.total;
        });
    },
    onClearSearch() {
      this.page = 1;
      this.search = null;
      this.selectedType = null;
      this.getUsers();
    },
    showAlert(alertType, title, text) {
      switch (alertType) {
        case "error":
          this.$swal({
            title: title,
            text: text,
            icon: "error",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-danger",
            },
          });
          break;
        case "info":
          this.$swal({
            title: title,
            text: text,
            icon: "info",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-info",
            },
          });
          break;
        default:
          // Caso para "success"
          this.$swal({
            title: title,
            text: text,
            icon: "success",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
          });
          break;
      }
    },
    updatePagination(opts) {
      if (this.pageSize !== opts.itemsPerPage) {
        this.pageSize = opts.itemsPerPage;
        this.page = 1;
      }
      this.page = opts.page;
      this.getUsers();
    },
    getPermissionsForView() {
      console.log(this.drawerItems);
      let currentView = this.drawerItems.find(
        (view) => view.value === ViewsEnum.USERS
      );
      if (currentView) {
        this.permissions = currentView;
      } else {
        this.$router.go(-1);
      }
    },
    changePassword() {
      if (!this.currentItem || !this.currentItem.id) {
        this.showAlert(
          "info",
          "Formulario incompleto",
          "Error: No se ha seleccionado un usuario válido para eliminar."
        );
        this.currentItem = null;
        return;
      }

      axios
        .post(
          process.env.VUE_APP_APIURL +
            "passwordChange/?id=" +
            this.currentItem.id,
          {
            password: this.changePasswordValue,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then(() => {
          this.showAlert(
            "success",
            "Actualizada",
            "Contraseña actualizada con éxito."
          );
          this.showChangePassword = false;
          this.changePasswordValue = null;
          this.changePasswordConfirmValue = null;
          this.getUsers();
        })
        .catch(() =>
          this.showAlert(
            "error",
            "Ocurrió un error",
            "No fue posible actualizar la contraseña."
          )
        )
        .finally(() => {});
    },
  },
  mounted() {
    this.getUsers();
    this.getUserTypes();
    this.getPermissionsForView();
  },
};
</script>
