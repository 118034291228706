<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
  >
    <v-row class="py-1">
      <v-col  class="d-flex">
        <div
          class="drawer-toggler pa-5 ms-6 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="minifyDrawer"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>
      </v-col>
      <v-col
      class="d-flex align-end justify-center"
      :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'"
      >
        <v-spacer></v-spacer> 
        <v-btn
          depressed
          :ripple="false"
          class="font-weight-600 text-body ls-0 text-capitalize pe-0 me-2"
          color="transparent"
          to="/login"
        >
          <v-icon size="16">fa fa-user me-sm-2 text-sm</v-icon>
          <span
            class="d-sm-inline font-weight-bold d-none"
            :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
            :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
            >Cerrar Sesion</span
          >
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>


<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
    };
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    performLogout() {
    this.$store.dispatch('auth/logout').then(() => {
      this.$router.push('/login'); 
    });
  }
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
  mounted() {
    this.minifyDrawer();
  },
};
</script>
