<template>
  <div>
    <v-card-title>Crear Equipo</v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-form ref="form" v-model="valid">
          <v-card class="border-radius-xl card-shadow mt-5 mb-5">
            <v-col>
              <v-row>
                <v-col>
                  <h3>Contabilidad</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Código SAT"
                    v-model="machine.SATCode"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
          <v-card class="border-radius-xl card-shadow mt-5 mb-5">
            <v-col>
              <v-row>
                <v-col>
                  <h3>Generales</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Marca"
                    v-model="machine.brand"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Modelo"
                    v-model="machine.model"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Serie"
                    v-model="machine.serialNumber"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Clave Interna"
                    v-model="machine.innerId"
                    :rules="[]"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    label="Pedimento"
                    v-model="machine.importRequest"
                    :rules="[]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="types"
                    label="Tipo"
                    v-model="machine.type"
                    :rules="[rules.required]"
                    item-text="name"
                    item-value="id"
                    :disabled="!!machine.techReview"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-file-input
                    v-model="images"
                    multiple
                    accept="image/*"
                    :rules="[]"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- Campo para editar youtubeUrl -->
                  <v-text-field
                    label="Enlaces relacionados (separados por comas)"
                    v-model="machine.youtubeUrl"
                    :rules="[rules.youtubeUrl]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <!-- PRECIOS -->
          <v-card class="border-radius-xl card-shadow mt-5 mb-5">
            <v-col>
              <v-row>
                <v-col>
                  <h3>Precios</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    type="number"
                    label="FMI"
                    v-model="machine.salesPriceFMI"
                    :rules="[]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    label="Frimar"
                    v-model="machine.salesPriceFrimar"
                    :rules="[]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="machine.priceCurrency"
                    :items="currencies"
                    label="Moneda"
                    single-line
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="machine.location"
                    :items="locations"
                    label="Ubicación"
                    single-line
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <!-- CAPACIDAD -->
          <v-card class="border-radius-xl card-shadow mt-5 mb-5">
            <v-col>
              <v-row>
                <v-col>
                  <h3>Capacidad</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    label="Español"
                    v-model="machine.spanishCapacity"
                    :rules="[]"
                  ></v-textarea>
                </v-col>
                <v-col>
                  <v-textarea
                    label="Inglés"
                    v-model="machine.englishCapacity"
                    :rules="[]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <!-- DESCRIPCIÓN -->
          <v-card class="border-radius-xl card-shadow mt-5 mb-5">
            <v-col>
              <v-row>
                <v-col>
                  <h3>Descripción</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    label="Español"
                    v-model="machine.spanishDescription"
                    :rules="[]"
                  ></v-textarea>
                </v-col>
                <v-col>
                  <v-textarea
                    label="Inglés"
                    v-model="machine.englishDescription"
                    :rules="[]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <!-- Archivos -->
          <v-card class="border-radius-xl card-shadow mt-5 mb-5">
            <v-col>
              <v-row>
                <v-col>
                  <h3>Archivos</h3>
                </v-col>
              </v-row>
              <v-row v-for="(file, index) in files" :key="index">
                <v-col>
                  <v-text-field
                    type="text"
                    label="Nombre del archivo"
                    v-model="file.filename"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-file-input
                    v-model="file.filePath"
                    :rules="[rules.required]"
                  ></v-file-input>
                </v-col>
                <v-col>
                  <v-btn color="red" @click="deleteFile"
                    ><span style="color: #ffffff">Eliminar</span></v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-content-end">
                  <v-btn color="#fdc408" @click="addFile"
                    ><span style="color: #ffffff">Agregar</span></v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <!-- ALTA -->
          <v-card class="border-radius-xl card-shadow mt-5 mb-5">
            <v-col>
              <v-row>
                <v-col>
                  <h3>Dado de alta en:</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h4>Frimar Maquinaria</h4>
                  <v-checkbox
                    v-model="machine.frimarWebsite"
                    label="Website"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.frimarCRM"
                    label="CRM"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.frimarSAI"
                    label="SAI"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.frimarInventory"
                    label="Calca Inventario"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.frimarDigitalCorridor"
                    label="Corredor Digital"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <h4>FMI Machinery</h4>
                  <v-checkbox
                    v-model="machine.fmiWebsite"
                    label="Website"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiCRM"
                    label="CRM"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiEbay"
                    label="Ebay"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiSurplus"
                    label="Surplus Record"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiMachinio"
                    label="Machinio"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiMachineSales"
                    label="MachineSales"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiCraiglist"
                    label="Craiglist"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiMachineryTrader"
                    label="MachineryTrader"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="machine.fmiInventory"
                    label="Calca Inventario"
                    single-line
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="black"
        class="btnApplyFilter mr-3"
        @click="updateItem"
        :disabled="!valid"
        style="color: #ffffff !important"
        >Crear</v-btn
      >
    </v-card-actions>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import { ViewsEnum } from "@/enums";

export default {
  name: "EquipmentCreate",
  components: {},
  data() {
    return {
      types: [],
      valid: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        youtubeUrl: (value) => {
          if (!value) return true;
          const urls = value.split(",");
          const pattern =
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
          return (
            urls.every((url) => pattern.test(url.trim())) ||
            "Una o más URLs son inválidas."
          );
        },
      },
      validCreation: false,
      images: [],
      listOptions: {},
      permissions: null,
      machine: {
        type: null,
        brand: null,
        model: null,
        serialNumber: null,
        origin: null,
        innerId: null,
        importRequest: null,
        spanishCapacity: null,
        englishCapacity: null,
        spanishDescription: null,
        englishDescription: null,
        SATCode: null,
      },
      currencies: ["USD", "MXN"],
      locations: ["FMI Machinery", "Frimar Maquinaria", "Otro"],
      files: [],
    };
  },
  computed: {
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
  },
  methods: {
    removeNullAttributes(obj) {
      for (let key in obj) {
        if (obj[key] === null) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          this.removeNullAttributes(obj[key]); // Recursively check nested objects
        }
      }
      return obj;
    },
    getMachineTypes() {
      let fullUrl = process.env.VUE_APP_APIURL + "machineTypes/";
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.types = response.data;
        });
    },
    updateItem() {
      this.validCreation = this.$refs.form.validate();
      if (!this.validCreation) {
        return; // Detiene la ejecución si el formulario no es válido
      }

      this.machine = this.removeNullAttributes(this.machine);

      const formData = new FormData();
      Object.keys(this.machine).forEach((key) => {
        formData.append(key, this.machine[key]);
      });

      this.images.forEach((image) => {
        formData.append(image.name, image);
      });

      this.files.forEach((file) => {
        formData.append(file.filename, file.filePath);
      });

      axios
        .post(process.env.VUE_APP_APIURL + "machine/", formData, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.showAlert(
            "success",
            "Equipo creado",
            "Equipo creado correctamente"
          );
          this.showCreateModal = false;
          this.images = [];
          this.$router.push("/app/equipment/");
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.innerId) {
              this.showAlert(
                "error",
                "Información no aceptada",
                "El ID interno ya existe."
              );
            }
            if (error.response.data.serialNumber) {
              this.showAlert(
                "error",
                "Información no aceptada",
                "El número de serie ya existe."
              );
            }
          } else {
            console.error("Error al crear el equipo:", error);
            this.showAlert(
              "error",
              "Información no aceptada",
              "Ocurrió un problema al crear el equipo"
            );
          }
        });
    },
    showAlert(alertType, title, text) {
      switch (alertType) {
        case "error":
          this.$swal({
            title: title,
            text: text,
            icon: "error",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-danger",
            },
          });
          break;
        case "info":
          this.$swal({
            title: title,
            text: text,
            icon: "info",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-info",
            },
          });
          break;
        default:
          // Caso para "success"
          this.$swal({
            title: title,
            text: text,
            icon: "success",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
          });
          break;
      }
    },
    getPermissionsForView() {
      let currentView = this.drawerItems.find(
        (view) => view.value === ViewsEnum.EQUIPMENT
      );
      if (currentView) {
        this.permissions = currentView;
      } else {
        this.$router.go(-1);
      }
    },
    addFile() {
      this.files.push({});
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
  },
  mounted() {
    this.getMachineTypes();
    this.getPermissionsForView();
  },
};
</script>
