const state = () => ({
  drawerSections: [],
});

// getters
const getters = {
  drawerSections(state) {
    return state.drawerSections;
  },
};

// actions
const actions = {
  setDrawerSections({ commit }, sections) {
    commit("setDrawerSections", sections);
  },
};

// mutations
const mutations = {
  setDrawerSections(state, sections) {
    state.drawerSections = sections;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
