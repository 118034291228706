<template>
    <button :class="{ 'primary-btn': isPrimary, 'secondary-btn': !isPrimary }" @click="handleClick" :disabled="isDisabled">
      {{ buttonText }}
    </button>
  </template>

<style scoped>
.secondary-btn {
  border: 1px solid #718096;
  border-radius: 10px;
  color: #000;
  background-color: white;
  padding: 5px 25px;
}

.primary-btn {
  border-radius: 10px;
  color: #FFF !important;
  background-color: #37474F;
  padding: 5px 25px;
  background-image: linear-gradient(to right, #465157, #151928);
}
</style>
  <script>
  export default {
    name: 'SingleBtn',
    props: {
      isPrimary: {
        type: Boolean,
        default: true 
      },
      buttonText: {
        type: String,
        default: 'Aceptar' 
      },
      clickHandler: {
        type: Function,
        required: true 
      },
      isDisabled: {
        type: Boolean, 
        default: false
      }
    },
    methods: {
      handleClick() {
        this.clickHandler();
      }
    }
  };
  </script>
  
  