<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <h2>Dashboard de seguimiento</h2>
      </v-row>
      <v-row>
        <v-col cols="12">
          <kanban-board
            :stages="stages"
            :blocks="computedBlocks"
            @update-block="updateDrops"
          >
            <div
              v-for="stage in stages"
              :slot="stage"
              :key="stage"
              class="column-header"
            >
              <v-col>
                <h2 class="column-title">
                  {{ stage }}
                </h2>
                <hr class="mb-0 pb-0" />
              </v-col>
            </div>
            <div
              v-for="block in computedBlocks"
              :slot="block.id"
              :key="block.id"
            >
              <kanban-card :block="block" :permissions="permissions"></kanban-card>
            </div>
          </kanban-board>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style>
.drag-column {
  background-color: whitesmoke !important;
}

.column-title {
  text-align: center !important;
  width: 100% !important;
}

.column-header {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

hr {
  border: none;
  height: 1px;
  width: 100%;
  background-color: gray;
  margin: 5px 0px 0px 0px;
}
</style>

<script>
import Vue from "vue";
import vueKanban from "vue-kanban";
import "vue-kanban/src/assets/kanban.scss";
import KanbanCard from "../../frimar/components/KanbanCard.vue";
import axios from "axios";
import { ViewsEnum } from "@/enums";

Vue.use(vueKanban);

export default {
  components: { KanbanCard },
  name: "Kanban",
  computed: {
    stages() {
      return this.phases.map((phase) => phase.name);
    },
    computedBlocks() {
      return this.blocks.map((block) => {
        return {
          id: block.id,
          status: block.status,
          title: block.machine.model,
          brand: block.machine.brand,
          innerId: block.machine.innerId,
          serialNumber: block.machine.serialNumber,
          overDate: "low",
          machineId: block.machine.id,
        };
      });
    },
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
  },
  data: function () {
    return {
      phases: [],
      blocks: [],
      permissions: null,
    };
  },
  methods: {
    getPhases() {
      axios
        .get(process.env.VUE_APP_APIURL + "phases/", {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.phases = response.data;
          this.getReviewsFromPhases();
        });
    },
    getReviewsFromPhases() {
      this.blocks = [];
      this.phases.forEach((phase) => {
        axios
          .get(process.env.VUE_APP_APIURL + "dashboard/?phaseId=" + phase.id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          })
          .then((response) => {
            response.data.forEach((item) => {
              this.blocks.push(item);
            });
          });
      });
    },
    updateDrops(id, newState) {
      let phase = this.phases.find((item) => item.name === newState);
      axios
        .put(
          process.env.VUE_APP_APIURL +
            "dashboard/?id=" +
            id +
            "&phaseId=" +
            phase.id,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then(() => {
          this.blocks = [];
          this.getReviewsFromPhases();
        })
        .catch((err) => {
          this.$swal({
            title: "No fue posible actualizar",
            text: err.response.data.message
              ? err.response.data.message
              : "Por favor, completa todos los campos requeridos.",
            icon: "error",
          });
          this.getReviewsFromPhases();
        });
    },
    getPermissionsForView() {
      let equipment = this.drawerItems.find(
        (view) => view.value === ViewsEnum.EQUIPMENT
      );
      let techReviews = this.drawerItems.find(
        (view) => view.value === ViewsEnum.TECH_REVIEW
      );

      if (equipment || techReviews) {
        this.permissions = {
          equipment: equipment,
          techReviews: techReviews,
        };
      } else {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    setInterval(() => {
      this.getPhases();
    }, 30000);
    this.getPhases();
    this.getPermissionsForView();
  },
};
</script>
