import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
//FRIMAR
import Equipment from "@/frimar/views/Equiptment.vue";
import TechnicalRevision from "@/frimar/views/TecnicalRevition.vue";
import Users from "../frimar/views/Users.vue";
import TechnicalReviewDetail from "@/frimar/views/TecnicalReviewDetail.vue";
import TechnicalReviewForm from "@/frimar/views/TecnicalReviewForm.vue";
import Login from "../frimar/views/Login.vue";
import Kanban from "../views/Applications/Kanban.vue";
import axios from "axios";
import store from "@/store";
import EquiptmentDetail from "@/frimar/views/EquiptmentDetail.vue";
import EquiptmentEdit from "@/frimar/views/EquiptmentEdit.vue";
import EquiptmentCreate from "@/frimar/views/EquiptmentCreate.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/app/equipment/",
  },
  {
    path: "/app",
    component: DashboardLayout,
    children: [
      {
        path: "equipment",
        name: "Equipment",
        component: Equipment,
      },
      {
        path: "tecnicalRevition",
        name: "TechnicalRevision",
        component: TechnicalRevision,
      },
      {
        path: "techReviewDetail/:id",
        name: "TechnicalReviewDetail",
        component: TechnicalReviewDetail,
      },
      {
        path: "techReviewForm/:id",
        name: "TechnicalReviewForm",
        component: TechnicalReviewForm,
      },
      {
        path: "users",
        name: "Users",
        component: Users,
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Kanban,
      },
      {
        path: "machine/:id",
        name: "Machine",
        component: EquiptmentDetail,
      },
      {
        path: "machine/edit/:id",
        name: "MachineEdit",
        component: EquiptmentEdit,
      },
      {
        path: "new/machine",
        name: "MachineCreate",
        component: EquiptmentCreate,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  store,
});

router.beforeEach((to, from, next) => {
  if (checkIfIsLogin(to, next) === false) {
    if (!router.app.$store.getters["auth/token"]) {
      next({ name: "Login" });
    } else {
      validateToken(next);
    }
  }
});

function checkIfIsLogin(to, next) {
  if (to.name === "Login") {
    router.app.$store.dispatch("auth/logout");
    router.app.$store.dispatch("sections/setDrawerSections", []);
    next();
    return true;
  }
  return false;
}

const validateToken = (next) => {
  axios
    .post(process.env.VUE_APP_BASE_URL + "project/token/verify/", {
      token: router.app.$store.getters["auth/token"],
    })
    .then(() => {
      next();
    })
    .catch(() => {
      axios
        .post(process.env.VUE_APP_BASE_URL + "project/token/refresh/", {
          refresh: router.app.$store.getters["auth/refreshToken"],
        })
        .then((response) => {
          router.app.$store.dispatch(
            "auth/setAccessToken",
            response.data.access
          );
          router.app.$store.dispatch(
            "auth/setRefreshToken",
            response.data.refresh
          );
          next();
        })
        .catch(() => {
          router.app.$store.dispatch("auth/logout");
          router.app.$store.dispatch("sections/setDrawerSections", []);
          next({ name: "Login" });
        });
    });
};

export default router;
