<template>
  <div>
    <div class="pa-4">
      <v-card-title>Editar Equipo</v-card-title>
      <v-card-text v-if="machine">
        <v-container fluid>
          <v-form ref="form" v-model="valid">
            <v-card  class="border-radius-xl card-shadow mt-5 mb-5">
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Contabilidad</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Código SAT"
                      v-model="machine.SATCode"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-if="showAccountantSection"
                      v-model="machine.checkedByAccountant"
                      label="Revisado por contabilidad"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
            <!-- general -->
            <v-card class="border-radius-xl card-shadow mt-5 mb-5">
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Generales</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Marca"
                      v-model="machine.brand"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Modelo"
                      v-model="machine.model"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Serie"
                      v-model="machine.serialNumber"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Clave Interna"
                      v-model="machine.innerId"
                      :rules="[]"
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      label="Pedimento"
                      v-model="machine.importRequest"
                      :rules="[]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      :items="types"
                      label="Tipo"
                      v-model="machine.type"
                      :rules="[rules.required]"
                      item-text="name"
                      item-value="id"
                      :disabled="!!machine.techReview"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-file-input
                      v-model="images"
                      multiple
                      accept="image/*"
                      :rules="[]"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <!-- Campo para editar youtubeUrl -->
                    <v-text-field
                      label="Enlaces relacionados (separados por comas)"
                      v-model="machine.youtubeUrl"
                      :rules="[rules.youtubeUrl]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <!-- PRECIOS -->
            <v-card class="border-radius-xl card-shadow mt-5 mb-5">
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Precios</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      type="number"
                      label="FMI"
                      v-model="machine.salesPriceFMI"
                      :rules="[]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="number"
                      label="Frimar"
                      v-model="machine.salesPriceFrimar"
                      :rules="[]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="machine.priceCurrency"
                      :items="currencies"
                      label="Moneda"
                      single-line
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="machine.location"
                      :items="locations"
                      label="Ubicación"
                      single-line
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <!-- CAPACIDAD -->
            <v-card class="border-radius-xl card-shadow mt-5 mb-5">
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Capacidad</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      label="Español"
                      v-model="machine.spanishCapacity"
                      :rules="[]"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-textarea
                      label="Inglés"
                      v-model="machine.englishCapacity"
                      :rules="[]"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <!-- DESCRIPCIÓN -->
            <v-card class="border-radius-xl card-shadow mt-5 mb-5">
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Descripción</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      label="Español"
                      v-model="machine.spanishDescription"
                      :rules="[]"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-textarea
                      label="Inglés"
                      v-model="machine.englishDescription"
                      :rules="[]"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <!-- ALTA -->
            <v-card class="border-radius-xl card-shadow mt-5 mb-5">
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Dado de alta en:</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>Frimar Maquinaria</h4>
                    <v-checkbox
                      v-model="machine.frimarWebsite"
                      label="Website"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.frimarCRM"
                      label="CRM"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.frimarSAI"
                      label="SAI"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.frimarInventory"
                      label="Calca Inventario"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.frimarDigitalCorridor"
                      label="Corredor Digital"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <h4>FMI Machinery</h4>
                    <v-checkbox
                      v-model="machine.fmiWebsite"
                      label="Website"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiCRM"
                      label="CRM"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiEbay"
                      label="Ebay"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiSurplus"
                      label="Surplus Record"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiMachinio"
                      label="Machinio"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiMachineSales"
                      label="MachineSales"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiCraiglist"
                      label="Craiglist"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiMachineryTrader"
                      label="MachineryTrader"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="machine.fmiInventory"
                      label="Calca Inventario"
                      single-line
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <!-- Archivos -->
            <v-card class="border-radius-xl card-shadow mt-5 mb-5">
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Archivos</h3>
                  </v-col>
                </v-row>
                <v-row v-for="(file, index) in preUploadedFiles" :key="index">
                  <v-col>
                    <a :href="file.filePath" target="_blank" style="color: rgba(253, 196, 8, 1);text-decoration: none;">
                      {{file.filename}}
                    </a>
                  </v-col>
                  <v-col>
                    <v-btn color="red" @click="deletePreUploadedFile(index)"
                      ><span style="color: #ffffff">Eliminar</span></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row v-for="(file, index) in files" :key="index">
                  <v-col>
                    <v-text-field
                      type="text"
                      label="Nombre del archivo"
                      v-model="file.filename"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-file-input
                      v-model="file.filePath"
                      :rules="[rules.required]"
                    ></v-file-input>
                  </v-col>
                  <v-col>
                    <v-btn color="red" @click="deleteFile(index)"
                      ><span style="color: #ffffff">Eliminar</span></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-content-end">
                    <v-btn color="#fdc408" @click="addFile"
                      ><span style="color: #ffffff">Agregar</span></v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <!-- IMAGES -->

            <v-row v-for="image in machine.images" :key="image.id">
              <v-col>
                <v-card
                  class="card-shadow px-4 py-4 overflow-hidden border-radius-xl"
                >
                  <v-row>
                    <v-col cols="auto">
                      <img
                        :src="image.imagePath"
                        alt="Preview"
                        class="border-radius-lg"
                        width="80%"
                        style="max-width: 500px"
                      />
                    </v-col>
                    <v-col
                      lg="4"
                      md="6"
                      class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
                    >
                      <div class="d-flex align-center">
                        <v-btn
                          buttonText="Eliminar"
                          @click="deleteImage(image.id, machine.id)"
                          color="red"
                          ><span style="color: #ffffff">Eliminar</span></v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <single-btn
          :isPrimary="true"
          buttonText="Guardar"
          :clickHandler="updateItem"
          :isDisabled="!valid"
        ></single-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<style></style>

<script>
import SingleBtn from "../components/singleBtn.vue";
import axios from "axios";
import { MachineStatusEnum, ViewsEnum } from "@/enums";
import router from "@/router";

export default {
  name: "EquipmentEdit",
  components: {
    SingleBtn,
  },
  data() {
    return {
      types: [],
      valid: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        youtubeUrl: (value) => {
          if (!value) return true;
          const urls = value.split(",");
          const pattern =
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
          return (
            urls.every((url) => pattern.test(url.trim())) ||
            "Una o más URLs son inválidas."
          );
        },
      },
      validCreation: false,
      images: [],
      listOptions: {},
      permissions: null,
      machine: null,
      currencies: ["USD", "MXN"],
      locations: ["FMI Machinery", "Frimar Maquinaria", "Otro"],
      files: [],
      preUploadedFiles: [],
    };
  },
  computed: {
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
    showAccountantSection() {
      if (!this.machine) {
        return true;
      }
      return this.machine.status === MachineStatusEnum.ACCOUNTANT;
    },
  },
  methods: {
    removeNullAttributes(obj) {
      for (let key in obj) {
        if (obj[key] === null) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          this.removeNullAttributes(obj[key]); // Recursively check nested objects
        }
      }
      return obj;
    },
    addUrlField() {
      this.equipmentDetails.gallery.push("");
    },
    addUrlFieldEdit() {
      this.actionEquipment.Galeria.push("");
    },
    removeUrlField(index) {
      this.actionEquipment.Galeria.splice(index, 1);
    },
    getMachineById() {
      let fullUrl =
        process.env.VUE_APP_APIURL + "machine/?id=" + this.$route.params.id;
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.machine = response.data;
          this.preUploadedFiles = this.machine.files;
        });
    },
    getMachineTypes() {
      let fullUrl = process.env.VUE_APP_APIURL + "machineTypes/";
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.types = response.data;
        });
    },
    updateItem() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.machine = this.removeNullAttributes(this.machine);

      const formData = new FormData();
      Object.keys(this.machine).forEach((key) => {
        formData.append(key, this.machine[key]);
      });

      formData.append("fileIds", this.preUploadedFiles.map(file => file.id));

      this.images.forEach((image) => {
        formData.append(image.name, image);
      });

      this.files.forEach((file) => {
        formData.append(file.filename, file.filePath);
      });

      axios
        .put(
          process.env.VUE_APP_APIURL + "machine/?id=" + this.machine.id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.showAlert(
            "success",
            "Equipo actualizado",
            "Equipo actualizado correctamente"
          );
          this.images = [];
          router.push("/app/machine/" + this.$route.params.id);
        })
        .catch((error) => {
          console.error("Error al actualizar el equipo:", error);
          this.showAlert(
            "error",
            "Error",
            "Ocurrió un problema al actualizar el equipo"
          );
        });
    },
    showAlert(alertType, title, text) {
      switch (alertType) {
        case "error":
          this.$swal({
            title: title,
            text: text,
            icon: "error",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-danger",
            },
          });
          break;
        case "info":
          this.$swal({
            title: title,
            text: text,
            icon: "info",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-info",
            },
          });
          break;
        default:
          // Caso para "success"
          this.$swal({
            title: title,
            text: text,
            icon: "success",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
          });
          break;
      }
    },
    deleteImage(id, machineId) {
      let fullUrl = process.env.VUE_APP_APIURL + "machineImage/?id=" + id;
      axios
        .delete(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.showAlert(
            "success",
            "Imagen eliminada",
            "Imagen eliminada correctamente"
          );
          this.getMachineById(machineId, true);
        });
    },
    getPermissionsForView() {
      let currentView = this.drawerItems.find(
        (view) => view.value === ViewsEnum.EQUIPMENT
      );
      if (currentView) {
        this.permissions = currentView;
      } else {
        this.$router.go(-1);
      }
    },
    addFile() {
      this.files.push({});
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    deletePreUploadedFile(index) {
      this.preUploadedFiles.splice(index, 1);
    }
  },
  mounted() {
    this.getMachineById();
    this.getMachineTypes();
    this.getPermissionsForView();
  },
};
</script>
