<template>
  <div>
    <v-container fluid v-if="currentItem">
      <v-row class="pr-5">
        <v-card-title primary-title class="text-h5"
          >Detalles del Equipo</v-card-title
        >
        <v-spacer></v-spacer>
        <v-btn class="btnApplyFilter" @click="onEdit">Editar</v-btn>
      </v-row>
      <v-card
        v-if="currentItem.images && currentItem.images.length > 0"
        class="border-radius-xl card-shadow mt-5 mb-5"
      >
        <v-carousel
          max-width="444px"
          height="250px"
          :hide-delimiters="currentItem.images.length === 1"
          :show-arrows="currentItem.images.length > 1"
        >
          <v-carousel-item
            v-for="(image, index) in getImagePaths(currentItem.images)"
            :key="index"
            cover
          >
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img :src="image" style="max-width: 100%; max-height: 100%" />
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card>

      <!-- Conta -->
      <v-card class="border-radius-xl card-shadow mt-5 mb-5">
        <v-col>
          <v-row>
            <v-col>
              <h3>Contabilidad</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Código SAT: </span
                >{{ currentItem.SATCode }}</v-card-text
              >
            </v-col>
          </v-row>
        </v-col>
      </v-card>

      <!-- GENERALES -->
      <v-card class="border-radius-xl card-shadow mt-2 mb-5">
        <div class="px-6 py-6">
          <h3>Generales</h3>
          <v-row>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Marca: </span
                >{{ currentItem.brand }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Tipo: </span
                >{{ currentItem.typeName }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Modelo: </span
                >{{ currentItem.model }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Número de Serie: </span
                >{{ currentItem.serialNumber }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Clave interna: </span
                >{{ currentItem.innerId }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Origen: </span
                >{{ currentItem.oriigin }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Pedimento: </span
                >{{ currentItem.importRequest }}</v-card-text
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card-title class="font-weight-bold">Enlaces</v-card-title>
              <v-card-text>
                <div v-if="currentItem.youtubeUrl">
                  <a
                    v-for="(url, index) in currentItem.youtubeUrl.split(',')"
                    :key="index"
                    :href="url"
                    target="_blank"
                    style="color: rgba(253, 196, 8, 1); text-decoration: none"
                    >{{ url }}</a
                  >
                  <br
                    v-for="(url, index) in currentItem.youtubeUrl.split(',')"
                  />
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- PRECIOS -->

      <v-card class="border-radius-xl card-shadow mt-2 mb-5">
        <div class="px-6 py-6">
          <h3>Precios</h3>
          <v-row>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">FMI: </span
                >{{ currentItem.salesPriceFMI }}
                {{ currentItem.priceCurrency }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Frimar: </span
                >{{ currentItem.salesPriceFrimar }}
                {{ currentItem.priceCurrency }}</v-card-text
              >
            </v-col>
            <v-col>
              <v-card-text
                ><span class="font-weight-bold">Ubicado en: </span
                >{{ currentItem.location }}</v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- Capacidad -->

      <v-card class="border-radius-xl card-shadow mt-2 mb-5">
        <div class="px-6 py-6">
          <h3>Capacidad</h3>
          <v-row>
            <v-col cols="12" md="6">
              <v-card-text
                  style="white-space: pre"
                ><span class="font-weight-bold">Español: </span
                ><br>{{ currentItem.spanishCapacity }}</v-card-text
              >
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text
                  style="white-space: pre"
                ><span class="font-weight-bold">Ingles: </span
                ><br>{{ currentItem.englishCapacity }}</v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- Descripción -->

      <v-card class="border-radius-xl card-shadow mt-2 mb-5">
        <div class="px-6 py-6">
          <h3>Descripción o pendiente</h3>
          <v-row>
            <v-col cols="12" md="6">
              <v-card-text
                  style="white-space: pre"
                ><span class="font-weight-bold">Español: </span
                ><br>{{ currentItem.spanishDescription }}</v-card-text
              >
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text
                  style="white-space: pre"
                ><span class="font-weight-bold">Inglés: </span
                ><br>{{ currentItem.englishDescription }}</v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- Archivos -->

      <v-card class="border-radius-xl card-shadow mt-2 mb-5">
        <div class="px-6 py-6">
          <h3>Archivos</h3>
          <v-row class="d-block" v-if="currentItem.files">
            <v-col cols="12">
              <ul>
                <li v-for="fileItem in currentItem.files" :key="fileItem.id">
                  <a
                    :href="fileItem.filePath"
                    target="_blank"
                    style="color: rgba(253, 196, 8, 1); text-decoration: none"
                  >
                    {{ fileItem.filename }}
                  </a>
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- ALTA -->

      <v-card class="border-radius-xl card-shadow mt-2 mb-5">
        <div class="px-6 py-6">
          <h3>Dado de alta en:</h3>
          <v-row>
            <v-col cols="12" md="6">
              <h4>Frimar Maquinaria</h4>
              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.frimarWebsite"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Website</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.frimarCRM"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                CRM</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.frimarSAI"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                SAI</v-card-text
              >

              <v-card-text>
                <span
                  class="font-weight-bold"
                  v-if="currentItem.frimarInventory"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Calca Inventario</v-card-text
              >

              <v-card-text>
                <span
                  class="font-weight-bold"
                  v-if="currentItem.frimarDigitalCorridor"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Corredor Digital</v-card-text
              >
            </v-col>
            <v-col cols="12" md="6">
              <h4>FMI Machinery</h4>
              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.fmiWebsite"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Website</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.fmiCRM"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                CRM</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.fmiEbay"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Ebay</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.fmiSurplus"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Surplus Record</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.fmiMachinio"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Machinio</v-card-text
              >

              <v-card-text>
                <span
                  class="font-weight-bold"
                  v-if="currentItem.fmiMachineSales"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                MachineSales</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.fmiCraiglist"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Craiglist</v-card-text
              >

              <v-card-text>
                <span
                  class="font-weight-bold"
                  v-if="currentItem.fmiMachineryTrader"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                MachineryTrader</v-card-text
              >

              <v-card-text>
                <span class="font-weight-bold" v-if="currentItem.fmiInventory"
                  ><v-icon size="20" style="color: green"
                    >fas fa-check-circle me-sm-2 text-md</v-icon
                  ></span
                >
                <span class="font-weight-bold" v-else
                  ><v-icon size="20" style="color: red"
                    >fas fa-times-circle me-sm-2 text-md</v-icon
                  ></span
                >
                Calca Inventario</v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-spacer></v-spacer>
          <single-btn
            :isPrimary="false"
            buttonText="Regresar"
            :clickHandler="() => $router.go(-1)"
          ></single-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style></style>

<script>
import SingleBtn from "../components/singleBtn.vue";
import axios from "axios";
import MachineDetailCard from "@/frimar/components/MachineDetailCard.vue";
import { ViewsEnum } from "@/enums";

export default {
  name: "Equiptments",
  components: {
    SingleBtn,
  },
  data() {
    return {
      equipment: [],
      currentItem: null,
    };
  },
  computed: {
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
  },
  methods: {
    viewItem(item) {
      this.showDetailModal = true;
      this.currentItem = {
        ...item,
        youtubeUrls: item.youtubeUrl ? item.youtubeUrl.split(",") : [],
      };
    },
    getMachineById() {
      let fullUrl =
        process.env.VUE_APP_APIURL + "machine/?id=" + this.$route.params.id;
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.currentItem = response.data;
        });
    },
    getMachineByClickEvent(row) {
      if (this.permissions && this.permissions.view) {
        this.getMachineById(row.id, false);
      }
    },
    getMachineTypes() {
      let fullUrl = process.env.VUE_APP_APIURL + "machineTypes/";
      axios
        .get(fullUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
          },
        })
        .then((response) => {
          this.types = response.data;
        });
    },
    showAlert(alertType, title, text) {
      switch (alertType) {
        case "error":
          this.$swal({
            title: title,
            text: text,
            icon: "error",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-danger",
            },
          });
          break;
        case "info":
          this.$swal({
            title: title,
            text: text,
            icon: "info",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-info",
            },
          });
          break;
        default:
          // Caso para "success"
          this.$swal({
            title: title,
            text: text,
            icon: "success",
            confirmButtonText: "Entendido",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
          });
          break;
      }
    },
    getPermissionsForView() {
      let currentView = this.drawerItems.find(
        (view) => view.value === ViewsEnum.EQUIPMENT
      );
      if (currentView && currentView.view) {
        this.permissions = currentView;
      } else {
        this.$router.go(-1);
      }
    },
    getImagePaths(images) {
      return images.map((image) => {
        return image.imagePath;
      });
    },
    onEdit() {
      if (this.permissions && this.permissions.edit) {
        this.$router.push("/app/machine/edit/" + this.$route.params.id);
      }
    },
  },
  mounted() {
    this.getMachineById();
    this.getMachineTypes();
    this.getPermissionsForView();
  },
};
</script>

<style></style>
