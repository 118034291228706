<template>
  <div>
    <v-card class="pa-4">
      <!-- Filtros y Búsqueda -->
      <v-row>
        <!-- TEXT CARD -->
        <v-col cols="12" md="4">
          <v-card-title primary-title class="text-h5">
            Detalle de revisión
          </v-card-title>
        </v-col>
      </v-row>
      <!-- info de maquina -->
      <v-row v-if="techReview">
        <v-col cols="12" md="2">
          <strong>Marca: </strong> {{ techReview.techReview.machine.brand }}
        </v-col>
        <v-col cols="12" md="2">
          <strong>Tipo: </strong> {{ techReview.techReview.machine.typeName }}
        </v-col>
        <v-col cols="12" md="2">
          <strong>Modelo: </strong> {{ techReview.techReview.machine.model }}
        </v-col>
        <v-col cols="12" md="3">
          <strong>Clave interna: </strong>
          {{ techReview.techReview.machine.innerId }}
        </v-col>
        <v-col cols="12" :md="techReview.techReview.machine.image ? 2 : 3">
          <strong>Serie: </strong>
          {{ techReview.techReview.machine.serialNumber }}
        </v-col>
        <v-col v-if="techReview.techReview.machine.image" cols="12" md="1">
          <v-img
            :src="techReview.techReview.machine.image"
            style="max-width: 95%"
          ></v-img>
        </v-col>
      </v-row>
      <v-row v-if="techReview">
        <v-col>
          <review-timeline :items="techReview.info"> </review-timeline>
        </v-col>
      </v-row>
      <v-row v-if="techReview">
        <v-spacer></v-spacer>
        <v-btn
          v-if="permissions && permissions.additionalAction1"
          color="black"
          class="btnApplyFilter mr-3"
          @click="onMakeTechReview"
          >Realizar revisión</v-btn
        >
        <v-btn
          v-if="permissions && permissions.edit"
          color="black"
          class="btnDeleteFilter mr-3"
          outlined
          @click="this.markAsFinalized"
          >{{ btnText }}</v-btn
        >
      </v-row>
      <v-row v-if="techReview">
        <v-col>
          <v-row>
            <v-col>
              <strong>Comentarios de la revisión</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ul>
                <li v-for="comment in techReview.comments" :key="comment.id">
                  {{ comment.comments }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style>
.background {
  background-image: url("../../assets/img/welcome.jpg");
  background-size: cover;
  background-position: center;
  height: 30em;
  position: relative;
  /* Añade posición relativa para .background */
}

.overlay {
  position: absolute;
  /* Cambia la posición a absoluta */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del contenedor .background */
  height: 100%;
  /* Ocupa toda la altura del contenedor .background */
  display: flex;
  /* Hace que los elementos se alineen en el centro */
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(122, 118, 57, 0.619);
  /* Ajusta el color y la opacidad según sea necesario */
}

.welcome-text,
.user-text {
  color: white;
  margin: 0;
  /* Elimina los márgenes predeterminados */
  display: inline-block;
  /* Cambia el comportamiento a inline-block */
}

.btnDeleteFilter {
  border: 1px solid #000;
  /* borde negro */
  border-radius: 10px;
  /* bordes redondeados */
  color: #000;
  /* texto negro */
  background-color: white;
  /* fondo transparente */
  padding: 10px 25px;
  /* relleno horizontal y vertical */
}

.btnApplyFilter {
  border-radius: 10px;
  /* bordes redondeados */
  color: #fff !important;
  /* texto blanco */
  background-color: #37474f;
  /* color de fondo oscuro */
  padding: 10px 25px;
  /* relleno horizontal y vertical */
  background-image: linear-gradient(to right, #5f5f5f, #000000);
  /* degradado de ejemplo */
}
</style>
<script>
import axios from "axios";
import ReviewTimeline from "@/frimar/components/ReviewTimeline.vue";
import { ViewsEnum } from "@/enums";

export default {
  name: "TechReviewDetail",
  components: { ReviewTimeline },
  data() {
    return {
      techReview: null,
      permissions: null,
    };
  },
  computed: {
    drawerItems() {
      return this.$store.getters["sections/drawerSections"];
    },
    btnText() {
      return this.techReview.techReview.isFinalized
        ? "Reactivar revisión"
        : "Finalizar revisión";
    },
  },
  methods: {
    getTechReview() {
      axios
        .get(
          process.env.VUE_APP_APIURL +
            "techReview/?id=" +
            this.$route.params.id,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then((response) => {
          this.techReview = response.data;
        });
    },
    markAsFinalized() {
      let fullUrl =
        process.env.VUE_APP_APIURL + "techReview/?id=" + this.$route.params.id;
      axios
        .put(
          fullUrl,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
            },
          }
        )
        .then(() => {
          this.getTechReview();
        });
    },
    getPermissionsForView() {
      let currentView = this.drawerItems.find(
        (view) => view.value === ViewsEnum.TECH_REVIEW
      );
      if (currentView) {
        this.permissions = currentView;
      } else {
        this.$router.go(-1);
      }
    },
    onMakeTechReview() {
      this.$router.push("/app/techReviewForm/" + this.$route.params.id);
    },
  },
  mounted() {
    this.getTechReview();
    this.getPermissionsForView();
  },
};
</script>
