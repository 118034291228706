<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="card-shadow mb-30">
          <v-card-text class="card-padding">
            <v-timeline dense align-top class="timeline-line-color">
              <v-timeline-item
                v-for="(item) in items"
                :key="item.id"
                small
                class="timeline"
              >
                <template v-slot:icon>
                  <v-avatar size="33" :color="color">
                    <v-icon color="iconColor" size="16">{{
                      item.iconName
                    }}</v-icon>
                  </v-avatar>
                </template>

                <v-card width="480">
                  <v-card-title class="px-0 pt-2 pb-1">
                    <span class="text-muted text-caption ls-0 font-weight-600"
                      >{{ formatDate(item.created) }}</span
                    >
                  </v-card-title>
                  <v-card-text class="px-0">
                    <h5 class="text-h5 text-typo font-weight-600 mt-3 mb-0">
                      Revisión de {{ item.phaseName }}
                    </h5>
                    <p>
                       Generado por: {{ item.userName }}
                    </p>
                    <br>
                    <p v-if="item.changes">
                      Cambios: {{item.changes}}
                    </p>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "reviewTimeline",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    convertTZ(date, tzString) {
      return (typeof date === "string" ? new Date(date) : date).toLocaleString("es-MX", {timeZone: tzString});
    }
  },
  computed: {
    formatDate() {
      return (date) => {
        if(!date) {
          return "";
        }
        return this.convertTZ(date, "America/Mexico_City");
      }
    }
  },
  data() {
    return {
      iconColor: "#1aae6f",
      btn: "badge-success",
      color: "#b0eed3",
      timeline: [
        {
          title: "New message",
          color: "#b0eed3",
          iconColor: "#1aae6f",
          icon: "ni-bell-55",
          btn: "badge-success",
        },
        {
          title: "Product Issue",
          color: "#fdd1da",
          iconColor: "#f80031",
          icon: "ni-html5",
          btn: "badge-danger",
        },
        {
          title: "New likes",
          color: "#aaedf9",
          iconColor: "#03acca",
          icon: "ni-like-2",
          btn: "badge-info",
        },
        {
          title: "New message",
          color: "#b0eed3",
          iconColor: "#1aae6f",
          icon: "ni-bell-55",
          btn: "badge-success",
        },
        {
          title: "Product Issue",
          color: "#fdd1da",
          iconColor: "#f80031",
          icon: "ni-html5",
          btn: "badge-danger",
        },
      ],
    };
  },
};
</script>
